import React, { useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import firebaseService from "../service/FirebaseService";
import functionsService from "../service/FunctionsService";
import LanguageContext from "../context/lang-context/LanguageContext";
import Header from "./layout/header";

const options = {
  loop: true,
  autoplay: true,
  autoplayTimeout: 3500,
  autoplayHoverPause: true,
  responsiveClass: true,
  margin: 20,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

const ProductPage = (props) => {
  const {
    match: { params },
  } = props;
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const context = useContext(LanguageContext);
  const langOptions = context.language.options;
  const textData = context.language.data;

  useEffect(() => {
    firebaseService.db
      .collection("products")
      .doc(params.id)
      .get()
      .then((doc) => {
        if (doc.data())
          setPost({
            id: doc.id,
            ...doc.data(),
          });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [params.id]);

  if (loading) return null;

  const detailImageUrls = post
    ? post.detailImagesUrls
      ? post.detailImagesUrls
      : []
    : [];
  return (
    <div>
      <Header></Header>
      {post ? (
        <Container>
          <div className="projectTitle">
            <div>
              <h1>
                {functionsService.convertLanguageText(
                  post.productName,
                  post.productNameEn,
                  langOptions
                )}
              </h1>
            </div>
          </div>
          <Grid container spacing={5} className={"contentBack"}>
            <Grid item xs={12} align={"left"}>
              <div
                style={{ borderBottom: "4px solid #FE0000", width: "100px" }}
              />
            </Grid>
            <Grid item xs={12} className="detail-body-text">
              {functionsService.convertLanguageText(
                post.productDescriptionText,
                post.productDescriptionTextEn,
                langOptions
              )}
            </Grid>

            <Grid item xs={12}>
              <OwlCarousel className="owl-theme" {...options}>
                {detailImageUrls.map((url, i) => {
                  return (
                    <div key={`imageDetail_${i}`} className="projectImageItem">
                      <img src={url} alt={`imageDetail_${i}`} />
                    </div>
                  );
                })}
              </OwlCarousel>
            </Grid>
          </Grid>
        </Container>
      ) : null}
    </div>
  );
};

export default ProductPage;
