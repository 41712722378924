import React, {Component} from 'react'
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Footer from "./layout/footer";
import AOS from 'aos'
import Header from './layout/header';

AOS.init();

class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
        }
    }

    handleClose = () => {
        this.setState({
            dialogOpen: false
        })
    }

    handleOpen = () => {
        this.setState({
            dialogOpen: true
        })
    }

    renderDialog() {
        return (
            <Dialog onClose={this.handleClose} maxWidth={"md"} fullWidth={true} aria-labelledby="simple-dialog-title"
                    open={this.state.dialogOpen}>
                <div className="dialog">
                    <div className="closeDialog">
                        <IconButton onClick={() => {
                            this.handleClose()
                        }}>
                            <i className="fa fa-times"></i>
                        </IconButton>
                    </div>
                    <iframe width="100%" title="abount" height="100%" src="https://www.youtube.com/embed/3hbANK_hFz8"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </Dialog>
        )
    }

    render() {
        return (
            <div>
                <Header></Header>
                {this.renderDialog()}
                <div className="aboutBanner"></div>
                <div align="center">
                    <h1 style={{colo: '#4D4D4D'}}>Бидний тухай</h1>
                    <div style={{width: 120, background: '#ed1c24', height: 4, borderRadius: 3}}/>
                </div>
                <Container>
                    <div className="aboutContainer">
                        <Grid container spacing={10} alignItems={'center'}>
                            <Grid item xs={12} md={4}>
                                <h2 style={{margin: 0, fontWeight: 'bold', fontSize: 45}}>Бид хэн бэ?</h2>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                Бид Монгол орны төдийгүй бүс нутгийн онцгой цаг уурын нөхцөлд тэсвэртэй, ОУ-ын жишигт
                                бүрэн нийцсэн, авто замын бүх төрлийн түүхий эд, материалын цорын ганц үндэсний
                                үйлдвэрлэгч, худалдан борлуулагч, үйлчилгээ үзүүлэгч юм. Импортыг орлох бүтээгдэхүүн
                                үйлдвэрлэж эх орныхоо хэрэгцээг бүрэн хангахаас гадна бүс нутагтаа экспортлох нь бидний
                                зорилго юм.
                            </Grid>
                            <Grid item xs={12}
                                /*data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="200"
                                data-aos-offset="0"*/>
                                <div className="aboutImg">
                                    <IconButton onClick={() => this.handleOpen()}>
                                        <i className="fad fa-play-circle"></i>
                                    </IconButton>
                                    <div className="imgLinear"></div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <h2 style={{margin: 0, fontWeight: 'bold', fontSize: 45}}>Эрхэм зорилго</h2>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                Улс эх орныхоо хөгжилд бодит хувь нэмэр оруулах, компанийн бизнесийн тогтвортой
                                хөгжлийг хангах, нийгмийн өндөр хариуцлагатай ажиллах зорилтуудыг өмнөө тавин,
                                эрүүл аюулгүй орчныг бүрдүүлэх, инновацлаг, хэрэглэгчдийн шаардлагад нийцсэн
                                чанартай бүтээгдэхүүнийг үйлдвэрлэн хэрэглэгчдэд нийлүүлэхийг өөрсдийн эрхэм
                                зорилго болгон ажиллаж байна.
                            </Grid>

                            {/*<Grid item xs={12}>*/}
                                {/*<h2 style={{marginTop: 0, fontWeight: 'bold', fontSize: 25}}>Эрхэм зорилго</h2>*/}
                                {/*Улс эх орныхоо хөгжилд бодит хувь нэмэр оруулах, компанийн бизнесийн тогтвортой*/}
                                {/*хөгжлийг хангах, нийгмийн өндөр хариуцлагатай ажиллах зорилтуудыг өмнөө тавин,*/}
                                {/*эрүүл аюулгүй орчныг бүрдүүлэх, инновацлаг, хэрэглэгчдийн шаардлагад нийцсэн*/}
                                {/*чанартай бүтээгдэхүүнийг үйлдвэрлэн хэрэглэгчдэд нийлүүлэхийг өөрсдийн эрхэм*/}
                                {/*зорилго болгон ажиллаж байна.*/}
                            {/*</Grid>*/}

                        </Grid>
                    </div>
                </Container>

                <div className="grayBg">
                    <Container>
                        <Grid container alignItems={'center'} spacing={10}>
                            <Grid item xs={12} align={'center'}>
                                <h1 style={{colo: '#4D4D4D'}}>Яагаад БИТУМИНА гэж?</h1>
                                <div style={{width: 120, background: '#ed1c24', height: 4, borderRadius: 3}}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <img style={{borderRadius: 3}} width={'100%'} src='/assets/img/about/team.jpg'
                                     alt={''}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <div>
                                    <h2 style={{marginTop: 0, fontWeight: 'bold', fontSize: 25}}>Мэргэшсэн баг, хамт
                                        олон</h2>
                                    <p>
                                        Бид ажилтнуудынхаа мэргэжлийн туршлага, сургалт, хөгжилд үргэлж анхаардаг.
                                        Компанийн
                                        менежмент, багийн ажиллагаа, харилцаа, сургалтын зөв уялдаа холбоо нь манай хамт
                                        олонд
                                        зорилгодоо хүрэхэд шаардагдах бүтээмж, ур чадварыг бий болгодог.
                                    </p>
                                </div>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <img width={'100px'} src={'/assets/img/about/Afaq.png'} alt={''}/>
                                <h2>Өндөр чанартай
                                    бүтээгдэхүүн</h2>
                                Бид Монгол улсын болон олон улсын чанарын стандартыг мөрдөж удирдлагын болон
                                гүйцэтгэлийн бүх түвшинд чанарын менежментийн тогтолцоог бүрдүүлэн ажиллахыг эрхэмлэдэг.
                                Мөн ISO 9001:2015 стандартыг нэвтрүүлэн дэлхийд тэргүүлэгч Францын АФНОР группээр
                                аудитлагдаж баталгаажсан.
                                <h2>Дотоод хяналт</h2>
                                Бүтээгдэхүүний үйлдвэрлэлийн явцад байнгын чанарын хяналт хийх өндөр түвшний мэргэшсэн
                                хүний нөөц болон олон улсын стандартын лабораторитай.
                                <h2>Найдвартай байдал</h2>
                                Яг цагтаа хүрэх найдвартай үйлчилгээний менежмент, харилцагчаа
                                хүндэлсэн, үнэнч, нээлттэй, шударга харилцааг бид эрхэмлэдэг.
                            </Grid>

                            <Grid item xs={12} md={6} align={'right'}>
                                <img width={'70%'} style={{borderRadius: 3}} src={'/assets/img/about/quality.jpg'}
                                     alt={''}/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div className="whiteBg">
                    <Container>
                        <Grid container spacing={10} alignItems={'center'} style={{height: '100%'}}>
                            <Grid item xs={12} align={'center'}>
                                <h1 style={{colo: '#4D4D4D'}}>Манай үйлдвэрүүд</h1>
                                <div style={{width: 120, background: '#ed1c24', height: 4, borderRadius: 3}}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <img style={{borderRadius: 3}} width={'100%'}
                                     src='/assets/img/about/uildwer1.jpg' alt={''}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <h2 style={{marginTop: 0, fontWeight: 'bold', fontSize: 25}}>Битум хайлуулах
                                    үйлдвэр</h2>
                                <p>
                                    Данийн Вант улсын Denimotech компанийн 350-400 тоннын багтаамжтай, Цагт 8-12 тонн
                                    бүтээгдэхүүн үйлдвэрлэх хүчин чадал бүхий 100% ногоон технологийн битум хайлуулах
                                    үйлдвэртэй.
                                </p>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <h2 style={{marginTop: 0, fontWeight: 'bold', fontSize: 25}}>Хүйтэн асфальтын
                                    үйлдвэр</h2>
                                <p>
                                    Евро стандартын дэвшилтэт технологи бүхий, өдөрт 120 тонн бүтээгдэхүүн үйлдвэрлэх
                                    хүчин чадалтай, орчин үеийн бүрэн автомат хүйтэн асфальтын үйлдвэртэй.
                                </p>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <img style={{borderRadius: 3}} width={'100%'}
                                     src='/assets/img/about/uildwer2.jpg' alt={''}/>
                            </Grid>

                            <Grid item xs={12} md={6} align={'center'}>
                                <img style={{borderRadius: 3}} width={'70%'}
                                     src='/assets/img/about/uildwer5.jpg' alt={''}/>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <h2 style={{marginTop: 0, fontWeight: 'bold', fontSize: 25}}>Авто замын тэмдэглэгээний
                                    термопластик будгийн үйлдвэр</h2>
                                <p>
                                    АНУ-ын AASHTO /American association of state highway and transportation of-ficial/
                                    стандартын дагуу цагт 15 тонн будаг үйлдвэрлэх хүчин чадалтай, тусгай зориулалтын,
                                    байгаль орчинд ээлтэй технологи бүхий агааржуулалт, тоос шингээх төхөөрөмжтэй aвто
                                    замын хэвтээ тэмдэглэгээний термопластик будгийн бүрэн автомат үйлдвэртэй..
                                </p>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                {/*<div className="aboutWorkContainer">
                    <Container>
                        <Grid container spacing={10}>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="500"
                                  data-aos-offset="0">
                                <img width={'100%'} src={'./../assets/img/logo.png'} alt={''}/>
                            </Grid>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="700"
                                  data-aos-offset="0">
                                <img width={'100%'} src={'./../assets/img/logo.png'} alt={''}/>
                            </Grid>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="900"
                                  data-aos-offset="0">
                                <img width={'100%'} src={'./../assets/img/logo.png'} alt={''}/>
                            </Grid>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="1100"
                                  data-aos-offset="0">
                                <img width={'100%'} src={'./../assets/img/logo.png'} alt={''}/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>*/}
                {/*<div className='aboutContainer'>
                    <Container>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={6}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="1200"
                                  data-aos-offset="0">
                                <img width={'100%'} style={{borderRadius: 3}} src={'./../assets/img/ceo.jpg'} alt={''}/>
                            </Grid>
                            <Grid item xs={12} md={6}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="1500"
                                  data-aos-offset="0">
                                <div className="ceoText" >
                                    <h2 style={{marginTop: 0, fontWeight: 'bold'}}>Захирлын мэндчилгээ</h2>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                    the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                    with desktop publishing software like Aldus PageMaker including versions of Lorem
                                    Ipsum.
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}><h3>Ажилчид</h3></Grid>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="500"
                                  data-aos-offset="0">
                                <div className="employeeImg">
                                    <img width={'100%'}
                                         src={'https://image.shutterstock.com/image-photo/portrait-smiling-millennial-man-sitting-260nw-1194497224.jpg'}
                                         alt={''}/>
                                    <div className="empHover">
                                        <div className="empHoverText">
                                            <h3>
                                                Албан тушаал
                                            </h3>
                                            <label>О.Нэр</label>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="700"
                                  data-aos-offset="0">
                                <div className="employeeImg">
                                    <img width={'100%'}
                                         src={'https://image.shutterstock.com/image-photo/portrait-smiling-red-haired-millennial-260nw-1194497251.jpg'}
                                         alt={''}/>
                                    <div className="empHover">
                                        <div className="empHoverText">
                                            <h3>
                                                Албан тушаал
                                            </h3>
                                            <label>О.Нэр</label>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="900"
                                  data-aos-offset="0">
                                <div className="employeeImg">
                                    <img width={'100%'}
                                         src={'https://image.shutterstock.com/image-photo/portrait-smiling-african-american-student-260nw-1194497215.jpg'}
                                         alt={''}/>
                                    <div className="empHover">
                                        <div className="empHoverText">
                                            <h3>
                                                Албан тушаал
                                            </h3>
                                            <label>О.Нэр</label>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="1100"
                                  data-aos-offset="0">
                                <div className="employeeImg">
                                    <img width={'100%'}
                                         src={'https://image.shutterstock.com/image-photo/portrait-smiling-millennial-man-sitting-260nw-1194497224.jpg'}
                                         alt={''}/>
                                    <div className="empHover">
                                        <div className="empHoverText">
                                            <h3>
                                                Албан тушаал
                                            </h3>
                                            <label>О.Нэр</label>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container>
                        <Grid container spacing={1}>
                            <Grid item xs={12}><h2>Зургийн цомог</h2></Grid>
                            <Grid item xs={12} md={8}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="500"
                                  data-aos-offset="0">
                                <div className="galleryItem" style={{backgroundImage: 'url(./../assets/img/about/uil.jpg)'}}></div>
                            </Grid>
                            <Grid item xs={12} md={4}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="700"
                                  data-aos-offset="0">
                                <div className="galleryItem" style={{backgroundImage: 'url(./../assets/img/about/office.jpg)'}}></div>
                            </Grid>
                            <Grid item xs={12} md={6}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="900"
                                  data-aos-offset="0">
                                <div className="galleryItem" style={{backgroundImage: 'url(./../assets/img/about/hural.jpg)'}}></div>
                            </Grid>
                            <Grid item xs={12} md={6}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="1100"
                                  data-aos-offset="0">
                                <div className="galleryItem" style={{backgroundImage: 'url(./../assets/img/about/employees.jpg)'}}></div>
                            </Grid>
                            <Grid item xs={12} md={12}
                                  data-aos="fade-zoom-in"
                                  data-aos-easing="ease-in-back"
                                  data-aos-delay="1300"
                                  data-aos-offset="0">
                                <div className="galleryItem" style={{backgroundImage: 'url(./../assets/img/about/allEmployees.jpg)'}}></div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>*/}
                <Footer></Footer>
            </div>
        )
    }
}

export default AboutPage;
