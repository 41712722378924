import React, { useState } from "react";
import Languages from "../../config/lang/Language";
import LanguageContext from "./LanguageContext";

const LanguageContextProvider = (props) => {
  const setLanguage = (options) => {
    setState({ ...state, language: options === Languages.mn.options ? Languages.en : Languages.mn})
  }

  const initState = {
    language: Languages.mn,
    setLanguage: setLanguage,
  };

  const [state, setState] = useState(initState);

  return (
    <LanguageContext.Provider value={state}>
      {props.children}
    </LanguageContext.Provider>
  );
};
export default LanguageContextProvider;
