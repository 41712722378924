const en = {
    homeLinkText: "Home",
    aboutLinkText: "About",
    serviceLinkText: "Service",
    projectsLinkText: "Products",
    contactLinkText: "Contact",
    ourServiceText: "Our servioutRecentWorksTextce",
    moreAboutUsText: "More about us!",
    outRecentWorksText: "News",
    exploreMoreText: "Explore more",
    servicesText: "Services",
    companyText: "Company",
    newsletterText: "Newsletter",
    contructionManageText: "Contruction Manage",
    contructionConsultantsText: "Contruction Consultants",
    architectureBuildingText: "Architecture & Building",
    homeRenovationsText: "Home Renovations",
    tailingPaintingText: "Tailing & Painting",
    interiorDesignText: "Interior Design",
    aboutUsText: "About Us",
    meetOurTeamText: "Meet Our Team",
    newsMediaText: "News & Media",
    contactsText: "Contacts",
    careersText: "Careers",
    newsletterInfoText: "Sign up for industry alerts, our latest news, thoughts, and insights from Promine.",
    haveAQuestionText: "Have a question?",
    clickMeText: "Click here",
    notFoundText: "Not found"
}
export default en;