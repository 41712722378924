import en from "./lang-en";
import mn from "./lang-mn";

const Languages = {
  mn: {
    data: mn,
    options: "mn", 
    text: "Монгол"
  },
  en: {
    data: en,
    options: "en", 
    text: "English"
  },
};

export default Languages;
