import {Component} from "react";
import {Container, Grid, Button} from "@material-ui/core";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import LanguageContext from "../../context/lang-context/LanguageContext";
import {Link} from 'react-router-dom';

function openNav() {
    document.getElementById("myNav").style.width = "60%";
}

function closeNav() {
    document.getElementById("myNav").style.width = "0%";
}

class Header extends Component {

    render() {
        const { language, setLanguage } = this.context;
        const textData = language.data;
        const nextLang = language.text === "Монгол" ? "English" : "Монгол";

        const changeLanguage = (e, name) => {
            if(name === "link")
                e.preventDefault();
            setLanguage(language.options);
        }
        return (
            <div>
                <div className="header">
                    <Container>
                        <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <img style={{width: "80%"}} src={'./../../assets/img/bituminaLogo.png'} alt={''}/>
                            </Grid>
                            <Grid item xs={10}>
                                <div>
                                    <nav className="stroke">
                                        <ul style={{ margin: "16px auto"}}>
                                            <li><Link to="/home">{textData.homeLinkText}</Link></li>
                                            <li><Link to="/products">{textData.projectsLinkText}</Link></li>
                                            <li><Link to="/services">{textData.serviceLinkText}</Link></li>
                                            <li><Link to="/about">{textData.aboutLinkText}</Link></li>
                                            <li><Link to="/contact">{textData.contactLinkText}</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </Grid>
                            {/* <Grid item xs={2} align={'right'}><Button style={{color: "#fff"}} onClick={(e) => changeLanguage(e, "button")}>{nextLang}</Button></Grid> */}
                        </Grid>
                    </Container>
                </div>
                <div className="respHeader">
                    <Grid container alignItems={'center'} justify={'center'}>
                        <Grid item xs={5} style={{padding: '10px 20px'}}>
                            <img width={'120'} src={'./../../assets/img/bituminaLogo.png'} alt={''}/>
                        </Grid>
                        <Grid item xs={6} align={'right'} style={{padding: '10px'}}>
                            <IconButton onClick={openNav}>
                                <i className="fad fa-bars" style={{color: '#ffffff'}}></i>
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
                <div id="myNav" className="overlay">
                    <Grid item xs={12} style={{textAlign: 'right'}}>
                        <IconButton onClick={closeNav}>
                            <i style={{color: '#3d3d3d'}} className="fa fa-times"></i>
                        </IconButton>
                    </Grid>
                    <div className="overlay-content">
                        <Link to="/home"><i className="fa fa-home"></i>{textData.homeLinkText}</Link>
                        <Link to="/products"><i className="fa fa-project-diagram"></i>{textData.projectsLinkText}</Link>
                        <Link to="/services"><i className="fa fa-concierge-bell"></i>{textData.serviceLinkText}</Link>
                        <Link to="/about"><i className="fa fa-building"></i>{textData.aboutLinkText}</Link>
                        <Link to="/contact"><i className="fa fa-address-book"></i>{textData.contactLinkText}</Link>
                        {/* <a href="#lang" onClick={(e) => changeLanguage(e, "link")}>{nextLang}</a> */}
                    </div>
                </div>
            </div>
        )
    }
}
Header.contextType = LanguageContext;

export default Header;
