import React, { useContext } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import LanguageContext from "../context/lang-context/LanguageContext";
import functionsService from "../service/FunctionsService";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  media: {
    height: 300,
  },
});

const News = (props) => {
  const classes = useStyles();
  const context = useContext(LanguageContext);
  const langOptions = context.language.options;
  const textData = context.language.data;
  const product = props.product;
  const index = props.index;

  const bodyText = functionsService.textLimit(
    functionsService.convertLanguageText(
      product.postBodyHtml,
      product.postBodyHtmlEn,
      langOptions
    ),
    150
  );

  const postName = functionsService.convertLanguageText(
    product.postName,
    product.postNameEn,
    langOptions
  );

  const showDetail = (data) => {
    props.history.push(`/news/${data.id}`);
  };

  return (
    <Grid item xs={12} style={{height: '100%'}}>
      <Card className={classes.root} elevation={0}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={product.imageUrl}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="h3"
              style={{ fontWeight: "bold" }}
            >
              {postName}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                height: "75px",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
              className="custom-card-body"
            >
              {bodyText}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              size="large"
              className="cardBtn"
              fullWidth
              onClick={(e) => showDetail(product)}
              endIcon={<i className="fa fa-arrow-right"></i>}
            >
              {textData.exploreMoreText}
            </Button>
          </div>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default withRouter(News);
