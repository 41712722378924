import React, { useState, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "./layout/footer";
import LanguageContext from "../context/lang-context/LanguageContext";
import FirebaseService from "../service/FirebaseService";
import Product from "./Product";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";
import Header from "./layout/header";

const ProductsPage = (props) => {
  const context = useContext(LanguageContext);
  const textData = context.language.data;
  const [loading, setLoading] = useState(true);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    FirebaseService.db
      .collection("products").orderBy("createdDate", "asc")
      .get()
      .then((snapshot) => {
        const datas = [];
        snapshot.forEach((doc) => {
          datas.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setPostList(datas);
        setLoading(false);
      });
  }, []);

  return (
    <div style={{background: "#eaeaea"}}>
      <Header></Header>
        <div className="projectBanner">
            <Container>
                <h1>
                    Хөгжлийн замыг <br /> цогцлооно
                </h1>
            </Container>
        </div>
      <Container disableGutters>
        <div style={{ display: "block", width: "100%", marginTop: "50px" }}>
          <Grid
            container
            spacing={3}
            className={"contentBack"}
            style={{padding: "30px 0", marginBottom: 50 }}
          >
            <Grid item xs={12} align="center">
              <div align="center">
                <h1>Бүтээгдэхүүн</h1>
                <div
                  style={{
                    width: 120,
                    background: "#ed1c24",
                    height: 4,
                    borderRadius: 3,
                  }}
                />
              </div>
            </Grid>
            {
              loading ? (
                <Grid container direction="row">
                  <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Skeleton variant="rect" height={300} width="95%" />
                    <Skeleton variant="text" animation="wave" width="60%" height={30} style={{margin: "10px 0 5px 0"}}/>
                    <Box>
                      <Skeleton variant="text" animation="wave" width="95%" height={20} />
                      <Skeleton variant="text" animation="wave" width="50%" height={20} />
                    </Box>
                    <Skeleton variant="text" animation="wave" width="95%" height={55} />
                  </Grid>
                  <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Skeleton variant="rect" height={300} width="95%" />
                    <Skeleton variant="text" animation="wave" width="60%" height={30} style={{margin: "10px 0 5px 0"}}/>
                    <Box>
                      <Skeleton variant="text" animation="wave" width="95%" height={20} />
                      <Skeleton variant="text" animation="wave" width="50%" height={20} />
                    </Box>
                    <Skeleton variant="text" animation="wave" width="95%" height={55} />
                  </Grid>
                  <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Skeleton variant="rect" height={300} width="95%" />
                    <Skeleton variant="text" animation="wave" width="60%" height={30} style={{margin: "10px 0 5px 0"}}/>
                    <Box>
                      <Skeleton variant="text" animation="wave" width="95%" height={20} />
                      <Skeleton variant="text" animation="wave" width="50%" height={20} />
                    </Box>
                    <Skeleton variant="text" animation="wave" width="95%" height={55} />
                  </Grid>
                </Grid>
              ) : (
                postList.map((item, i) => {
                  return <Product key={`prod_${i}`} product={item} index={i} />;
                })
              )
            }
          </Grid>
        </div>
      </Container>
      <Footer></Footer>
    </div>
  );
};

export default ProductsPage;
