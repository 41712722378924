import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { IconButton, Link } from "@material-ui/core";
import Header from "./layout/header";
import ReactMapboxGl, {Marker} from 'react-mapbox-gl';

const Map = ReactMapboxGl({  
  accessToken: "pk.eyJ1IjoiYml0dW1pbmEiLCJhIjoiY2thcTlpMmluMDkxczJ4cGxxaDlvNnd1OSJ9.qi3EiIQfhP7fegyhA9l9EA"
});

class ContactPage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      object: "office",
      centerCoordinates: {
        office: [106.918963, 47.915473],
        manifacture: [106.762418, 47.861269]
      },
      placeCoordinates: {
        office: [106.9212113, 47.913584],
        manifacture: [106.762069, 47.859175]
      },
      zoom: [13.5]
    }
  }

  renderMarkers(map, maps) {
    new maps.Marker({
      position: this.myLatLng,
      map,
      title: "Bitumina Mongolia LLC",
    });
  }

  changeObject = (e, object) => {
    e.preventDefault();
    this.setState({
      object
    })
  }

  render() {
    return (
      <div>
        <Header></Header>
        
        <div style={{ marginTop: 150 }}>
          <Container>
            <h1>Холбоо барих</h1>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <hr></hr>
                <div className="contact">
                  <div className="contactIcon">
                      <i className="fas fa-phone-alt"></i>
                  </div>
                  <div>
                    <label>+976 77117730</label>
                  </div>
                </div>
                <div className="contact">
                  <div className="contactIcon">
                      <i className="fas fa-at"></i>
                  </div>
                  <div>
                    <label>info@bitumina.mn</label>
                  </div>
                </div>
                <div className="contact">
                  <div className="contactIcon">
                    <i className="fa fa-location"></i>
                  </div>
                  <div>
                      <Link to="#office" style={{marginLeft: "14px", textDecorationLine: 'underline', cursor: "pointer"}} onClick={(e) => this.changeObject(e, "office")}><b>Төв оффис</b></Link><br/>
                    <label style={{marginLeft: "16px"}}>Улаанбаатар хот, 14241, Сүхбаатар дүүрэг,</label><br/>
                    <label style={{marginLeft: "16px"}}>1 дүгээр хороо, Олимпийн гудамж, 19А, </label><br/>
                    <label style={{marginLeft: "16px"}}>Шангри-ла оффис 20 давхар, 2005 тоот</label>
                  </div>
                </div>
                <div className="contact">
                  <div className="contactIcon">
                  <i className="fas fa-map-marked-alt"></i>
                  </div>
                  <div>
                  <Link to="#manifacture" style={{marginLeft: "14px", textDecorationLine: 'underline', cursor: "pointer"}} onClick={(e) => this.changeObject(e, "manifacture")}><b>Үйлдвэр</b></Link><br/>
                    <label style={{marginLeft: "16px"}}>Улаанбаатар хот, Баянгол дүүрэг,</label><br/>
                    <label style={{marginLeft: "16px"}}>20 дугаар хороо, Сонсголон зам, 5 тоот</label>
                  </div>
                </div>
                <div style={{ marginTop: "15px", marginBottom: "5px" }}>
                  <h4>Сошиал хаяг</h4>
                  <hr></hr>
                </div>
                <Grid item xs={12}>
                  <div style={{ display: "flex" }}>
                    <Link href="https://www.facebook.com/bitumina.mn" target="_blank" underline="none">
                      <IconButton className="soliacBtn fb-btn">
                        <i className="fab fa-facebook fa-fw"></i>
                      </IconButton>
                    </Link>
                    <Link href="https://www.youtube.com/channel/UCaElEAA6saSYIg0FSNQBMOw" target="_blank" underline="none">
                      <IconButton className="soliacBtn">
                        <i className="fab fa-youtube fa-fw"></i>
                      </IconButton>
                    </Link>
                    <Link href="https://twitter.com/bituminamn" target="_blank" underline="none">
                      <IconButton className="soliacBtn twitter-btn">
                        <i className="fab fa-twitter fa-fw"></i>
                      </IconButton>
                    </Link>
                    <Link href="https://www.instagram.com/bituminamongolia/" target="_blank" underline="none">
                      <IconButton className="soliacBtn instagram-btn">
                          <i className="fab fa-instagram"></i>
                      </IconButton>
                    </Link>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
              <Map
                style="mapbox://styles/mapbox/streets-v8"
                zoom={this.state.zoom}
                center={this.state.centerCoordinates[this.state.object]}
                on
                containerStyle={{
                  height: "100%",
                  width: "100%"
                }}>
                  <Marker
                    coordinates={this.state.placeCoordinates.office}
                  >
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                      <div style={{fontSize: 13, fontWeight: "bold"}}>"Битумина Монгол ХХК" Төв оффис</div>
                      <img alt="bitumina" height={30} width={30} src="/assets/img/logo-min.png"></img>
                    </div>
                  </Marker>
                  <Marker
                    coordinates={this.state.placeCoordinates.manifacture}
                  >
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                      <div style={{fontSize: 13, fontWeight: "bold"}}>"Битумина Монгол ХХК" Үйлдвэр</div>
                      <img alt="bitumina" height={30} width={30} src="/assets/img/logo-min.png"></img>
                    </div>
                  </Marker>
                </Map>
                {/* <MapBox object={this.state.object}/> */}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

export default ContactPage;
