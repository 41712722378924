class FunctionsService {

    convertLanguageText = (mnText, enText, lang) => {
        
        let result = ""
        if(lang === "mn") {
            if(mnText)
                result = mnText;
            else    
                result = enText;
        } else {
            if(enText)
                result = enText;
            else
                result = mnText;
        }

        return result;
    }

    textLimit = (text, limit) => {

        if (text.length > limit) {
          return `${text.substring(0, limit)}...`;
        } 
        return text;
        
    };
}

export default new FunctionsService();