import React, { Suspense } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import LanguageContextProvider from "./context/lang-context/LanguageContextProvider";
import CircularLoader from "./common/CircularLoader";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import ServicesPage from "./pages/ServicesPage";
import ServicePage from "./pages/ServicePage";
import ProductsPage from "./pages/ProductsPage";
import NewsPage from "./pages/NewsPage";
import ProductPage from "./pages/ProductPage";
import AboutPage from "./pages/AboutPage";


// const HomeIndex = React.lazy(() => import("./pages/index"));
const AdminPage = React.lazy(() => import("./pages/admin/index"));
const LoginForm = React.lazy(() => import("./pages/admin/LoginForm"));

// const HomePage = React.lazy(() => import("./pages/HomePage"));
// const AboutPage = React.lazy(() => import("./pages/AboutPage"));
// const ContactPage = React.lazy(() => import("./pages/ContactPage"));
// const ServicesPage = React.lazy(() => import("./pages/ServicesPage"));
// const ServicePage = React.lazy(() => import("./pages/ServicePage"));
// const ProductsPage = React.lazy(() => import("./pages/ProductsPage"));
// const NewsPage = React.lazy(() => import("./pages/NewsPage"));
// const ProductPage = React.lazy(() => import("./pages/ProductPage"));

const App = (props) => {
  return (
    <LanguageContextProvider>
      <Router>
        <Suspense fallback={<CircularLoader />}>
            <Route exact path="/" component={HomePage} />
            <Route path="/home" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/services" component={ServicesPage} />
            <Route path="/service/:id" component={ServicePage} />
            <Route path="/products" component={ProductsPage} />
            <Route path="/news/:id" component={NewsPage} />
            <Route path="/product/:id" component={ProductPage} />
            <Route path="/admin" render={(props) => <AdminPage {...props} />} />
            <Route
              path={`/login`}
              render={(props) => <LoginForm {...props} />}
            />
            {/* <Route path="/index" render={(props) => <HomeIndex {...props} />} />
            <Route path="/admin" render={(props) => <AdminPage {...props} />} />
            <Route
              path={`/login`}
              render={(props) => <LoginForm {...props} />}
            /> */}
            {/* <Redirect to="/" /> */}
        </Suspense>
      </Router>
    </LanguageContextProvider>
  );
};

export default App;
