import {Component} from "react";
import React from "react";
import {Container, Grid, IconButton, Link} from "@material-ui/core";
import FirebaseService from "../../service/FirebaseService";

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileUrl: null
        };
    }

    componentDidMount() {
        FirebaseService.storage.ref(`otherFiles/borshuur.pdf`).getDownloadURL()
            .then(url => {     
                this.setState({
                    fileUrl: url
                })
            })
    }

    render() {
        return (
            <div style={{display: 'block', position: 'relative'}}>
                <div style={{background: '#2d2d2d',}}>
                    <Container style={{paddingTop: 60, paddingBottom: 60, color: '#fff'}}>
                        <Grid container justify='flex-start' spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={7}>
                                        <img alt={''} width={'150px'} src={'/assets/img/bituminaLogo.png'}/>
                                    </Grid>
                                    <Grid item xs={5} style={{display: "flex", justifyContent: "flex-end"}}>
                                        <img alt={''} width={'80px'} src={'/assets/img/about/Afaq.png'}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    Бид Монгол орны онцгой цаг уурын нөхцөлд тэсвэртэй, авто замын бүх төрлийн түүхий эд, материалын үндэсний үйлдвэрлэгч, худалдан борлуулагч, үйлчилгээ үзүүлэгч юм.
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{display: 'flex'}}>
                                        <Link href="https://www.facebook.com/bitumina.mn" target="_blank" underline="none">
                                            <IconButton className="soliacBtn fb-btn">
                                            <i className="fab fa-facebook fa-fw"></i>
                                            </IconButton>
                                        </Link>
                                        <Link href="https://www.youtube.com/channel/UCaElEAA6saSYIg0FSNQBMOw" target="_blank" underline="none">
                                            <IconButton className="soliacBtn">
                                            <i className="fab fa-youtube fa-fw"></i>
                                            </IconButton>
                                        </Link>
                                        <Link href="https://twitter.com/bituminamn" target="_blank" underline="none">
                                            <IconButton className="soliacBtn twitter-btn">
                                            <i className="fab fa-twitter fa-fw"></i>
                                            </IconButton>
                                        </Link>
                                        <Link href="https://www.instagram.com/bituminamongolia/" target="_blank" underline="none">
                                            <IconButton className="soliacBtn instagram-btn">
                                                <i className="fab fa-instagram"></i>
                                            </IconButton>
                                        </Link>
                                        <a href={this.state.fileUrl} download="borshuur.pdf" target="_blank">
                                            <IconButton className="soliacBtn" onClick={this.downloadFile}>
                                                <i className="fas fa-download"></i>
                                            </IconButton>
                                        </a>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <strong>Үйлчилгээ</strong>
                                    </Grid>
                                    <Grid item xs={12}>Автомашин, тоног төхөөрөмжийн түрээс</Grid>
                                    <Grid item xs={12}>Битумын хүргэлт, нийлүүлэлт</Grid>
                                    <Grid item xs={12}>Лабораторийн шинжилгээ</Grid>
                                    <Grid item xs={12}>Зам засвар арчилгаа</Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <strong>Холбоо барих</strong>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="contactHome">
                                            <div>
                                                <i className="fas fa-phone-alt"></i>
                                                <label>+976 77117730</label>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="contactHome">
                                            <div>
                                                <i className="fas fa-at"></i>
                                                <label>info@bitumina.mn</label>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="contactHome">
                                            <div>
                                                <i className="fa fa-location"></i>
                                                <label>Улаанбаатар хот, 14241, Сүхбаатар дүүрэг, 1 дүгээр хороо, Олимпийн гудамж, 19А, Шангри-ла оффис 20 давхар, 2005 тоот</label>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div style={{background: '#1d1d1d'}}>
                    <Container style={{paddingTop: 20, paddingBottom: 20, color: '#fff'}}>
                        <Grid container justify='center' spacing={3}>
                            <Grid item xs={6} md={6}>Terms & Conditions - Privacy Policy - Sitemap</Grid>
                            <Grid item xs={6} md={6} align='right'>©2020 <label style={{color: '#FE0000'}}>Bitumina
                                Mongolia LLC</label></Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        )
    }
}

export default Footer;