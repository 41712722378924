const mn = {
    homeLinkText: "Нүүр",
    aboutLinkText: "Бидний тухай",
    serviceLinkText: "Үйлчилгээ",
    projectsLinkText: "Бүтээгдэхүүн",
    contactLinkText: "Холбоо барих",
    ourServiceText: "Бүтээгдэхүүн",
    moreAboutUsText: "Бидний тухай",
    outRecentWorksText: "Мэдээ мэдээлэл",
    exploreMoreText: "Дэлгэрэнгүй",
    servicesText: "Үйлчилгээнүүд",
    companyText: "Компани",
    newsletterText: "Мэдээллийн хэсэг",
    contructionManageText: "Contruction Manage",
    contructionConsultantsText: "Contruction Consultants",
    architectureBuildingText: "Architecture & Building",
    homeRenovationsText: "Home Renovations",
    tailingPaintingText: "Tailing & Painting",
    interiorDesignText: "Interior Design",
    aboutUsText: "About Us",
    meetOurTeamText: "Meet Our Team",
    newsMediaText: "News & Media",
    contactsText: "Contacts",
    careersText: "Careers",
    newsletterInfoText: "Sign up for industry alerts, our latest news, thoughts, and insights from Promine.",
    haveAQuestionText: "Have a question?",
    clickMeText: "Click here",
    notFoundText: "Мэдээлэл олдсонгүй"
}
export default mn;