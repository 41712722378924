import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/storage";
import firebaseConfig from "../config/FirebaseConfig";

class FirebaseService {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.db = app.firestore();
    this.auth = app.auth();
    this.storage = app.storage();
    this.DEFAULT_PASSWORD = "12345678";
  }

  uploadImage = (image, name, changePercent) => {
    const uploadTask = this.storage.ref(`images/${name}`).put(image);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          changePercent(progress);
        },
        (error) => {
          reject(error.message);
        },
        () => {
          this.storage
            .ref(`images/${name}`)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  };

  getAllImages = () => {
    const urls = [];
    this.storage
      .ref(`images`)
      .listAll()
      .then((res) => {
        res.items.forEach((itemRef) => {
          itemRef.getDownloadURL().then((url) => {
            urls.push(url);
          });
          //   const index = datas.findIndex(
          //     (item) => `${item.id}.${item.imageFormat}` === itemRef.name
          //   );
          //   if (index > -1) {
          //     itemRef.getDownloadURL().then((url) => {
          //       datas[index].imageUrl = url;
          //     });
          //   }
        });
      });
  };

  signUpEmail = (email, pass) => {
    let password = pass ? pass : this.DEFAULT_PASSWORD;

    return new Promise((resolve, reject) => {
      this.auth
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          resolve(userCredential);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  signIn = (email, pass) => {
    return new Promise((resolve, reject) => {
      this.auth
        .signInWithEmailAndPassword(email, pass)
        .then((userCredential) => {
          resolve(userCredential);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default new FirebaseService();
