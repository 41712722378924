import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Container,
  Button,
  Box,
} from "@material-ui/core";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import firebaseService from "../service/FirebaseService";
import LanguageContext from "../context/lang-context/LanguageContext";
import News from "./News";
import Footer from "./layout/footer";
import "./home.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import Header from "./layout/header";
import {Link} from 'react-router-dom';

const options = {
  loop: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  autoplayHoverPause: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
      nav: false,
    },
    1000: {
      items: 1,
      loop: false,
    },
  },
};

const optionsNews = {
  margin: 20,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

const HomePage = (props) => {
  const context = useContext(LanguageContext);
  const textData = context.language.data;
  const [loading, setLoading] = useState(true);
  const [bannerImages, setBannerImages] = useState([]);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    firebaseService.db
      .collection("banner").orderBy("name", "desc").limit(1)
      .get()
      .then((bannerSnapshot) => {
        const banners = [];
        bannerSnapshot.forEach((doc) => {
          banners.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setBannerImages(banners[0].detailImagesUrls);

        firebaseService.db
          .collection("posts")
          .orderBy("createdDate", "desc")
          .get()
          .then((snapshot) => {
            const datas = [];
            snapshot.forEach((doc) => {
              datas.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            setPostList(datas);
            setLoading(false);
          });
      })
  }, []);

  return (
    <div>
      <Header></Header>
      <div>

          {
            loading ? (
              <div
                className="homeBanner"
                style={{
                  background: "linear-gradient(90deg, rgba(0,0,0,.4), rgba(0,0,0,.4))",
                }}
              ></div>
            ) : (
              <OwlCarousel className="owl-theme" {...options}>
                {
                  bannerImages.map((url ,i) => {
                    return (
                      <div
                        key={`banner_${i}`}
                        className="homeBanner"
                        style={{
                          backgroundImage: `linear-gradient(90deg, rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${url})`,
                        }}
                      ></div>
                    )
                  })
                }
              </OwlCarousel>
            )
          }

        <div className="bannerText" >
          <Container>
            <h1>
              Хөгжлийн
              <br /> замыг цогцлооно.
            </h1>
            <Grid container>
              <div className="bannerButtons">
                <Link to="/products" style={{textDecoration: "none"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="redButton"
                    endIcon={<i className="fa fa-arrow-right"></i>}
                  >
                    {textData.ourServiceText}
                  </Button>
                </Link>
                <Link to="/about" style={{textDecoration: "none"}} className="rightBtn">
                  <Button variant="contained" size="large" className="whiteButton">
                    {textData.moreAboutUsText}
                  </Button>
                </Link>
                </div>
            </Grid>
          </Container>
        </div>
      </div>

      <div style={{background: "#eaeaea", padding: '20px 0'}}>
        <div align="center">
          <div align="center">
            <h1 style={{colo:'#4D4D4D'}}>{textData.outRecentWorksText}</h1>
            <div style={{width: 120, background: '#ed1c24', height: 4, borderRadius: 3}}/>
          </div>
        </div>
        <Container style={{ padding: "30px", marginBottom: 50 }}>
          <Grid container justify="center">
            {
              loading ?
              (
                <Grid container direction="row">
                  <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Skeleton variant="rect" height={300} width="95%" />
                    <Skeleton variant="text" animation="wave" width="60%" height={30} style={{margin: "10px 0 5px 0"}}/>
                    <Box>
                      <Skeleton variant="text" animation="wave" width="95%" height={20} />
                      <Skeleton variant="text" animation="wave" width="50%" height={20} />
                    </Box>
                    <Skeleton variant="text" animation="wave" width="95%" height={55} />
                  </Grid>
                  <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Skeleton variant="rect" height={300} width="95%" />
                    <Skeleton variant="text" animation="wave" width="60%" height={30} style={{margin: "10px 0 5px 0"}}/>
                    <Box>
                      <Skeleton variant="text" animation="wave" width="95%" height={20} />
                      <Skeleton variant="text" animation="wave" width="50%" height={20} />
                    </Box>
                    <Skeleton variant="text" animation="wave" width="95%" height={55} />
                  </Grid>
                  <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Skeleton variant="rect" height={300} width="95%" />
                    <Skeleton variant="text" animation="wave" width="60%" height={30} style={{margin: "10px 0 5px 0"}}/>
                    <Box>
                      <Skeleton variant="text" animation="wave" width="95%" height={20} />
                      <Skeleton variant="text" animation="wave" width="50%" height={20} />
                    </Box>
                    <Skeleton variant="text" animation="wave" width="95%" height={55} />
                  </Grid>
                </Grid>
              ) :
              (
                <OwlCarousel className="owl-theme" {...optionsNews}>
                  {
                    postList.map((item, i) => {
                      return (<News key={`post_${i}`} product={item} index={i}/>)
                    })
                  }
                </OwlCarousel>
              )
            }
          </Grid>
        </Container>
      </div>
      <div style={{padding: '15px 0'}}>
        <Container>
          <Grid container alignItems={'center'} spacing={5}>
            <Grid item xs={6} md={3}>
              <div className="logo1"></div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className="logo2"></div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className="logo3"></div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div className="logo4" ></div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default HomePage;
