const firebaseConfig = {
  apiKey: "AIzaSyDzHwgsc4Bq7DumCKs3Lk5lmKV8WRLfBvM",
  authDomain: "bitumina-74934.firebaseapp.com",
  databaseURL: "https://bitumina-74934.firebaseio.com",
  projectId: "bitumina-74934",
  storageBucket: "bitumina-74934.appspot.com",
  messagingSenderId: "92409508867",
  appId: "1:92409508867:web:5f3fdde8aa1aa7909dca36",
  measurementId: "G-CJQ3GF35BY"
};

export default firebaseConfig;
